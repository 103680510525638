<template>

  <div>
  <b-modal id="changeAddress"
           title="Adresse ändern"
           header-bg-variant="dark"
           :hide-footer="isAddressStoring">
    <template #modal-footer="{}">
      <b-button variant="secondary" @click="cancelChangeAddress" class="btn btn-default btn-form">Abbrechen</b-button>
      <b-button variant="primary" @click="submitForm" class="btn btn-default btn-form">Speichern</b-button>
    </template>
    <div class="text-center my-2" v-if="isAddressStoring">
      <b-spinner class="align-middle"></b-spinner>
      <strong class="ml-2">Die Adresse wird gespeichert...</strong>
    </div>
    <div v-else>
      <p class="my-4 alert alert-warning" role="alert" v-if="hasAddressError">Beim Speichern ist ein Fehler aufgetreten.</p>
      <div class="my-4" v-else>
        <p v-if="addressData.zweck === 'Postadresse privat'">Bitte geben Sie hier Ihre Anschrift ein.</p>
        <p v-else-if="addressData.zweck === 'Lieferadresse (Straße)'">Bitte geben Sie hier Ihre Anschrift (Lieferadresse) ein.</p>
        <p v-else>Bitte geben Sie hier Ihre abweichende Postanschrift ein.</p>
        <b-form id="addressForm">
          <div class="form-row">
            <div class="form-group col-md-9">
              <label class="small">Strasse</label>
              <b-form-input
                  type="text"
                  class="form-control form-control-sm"
                  aria-describedby="streetInputField-live-feedback"
                  v-model.trim="$v.form.strasse.$model"
                  :state="validateState('strasse')">
              </b-form-input>
              <b-form-invalid-feedback id="streetInputField-live-feedback">
                <span v-if="!$v.form.strasse.maxLength">Ihr Strassename darf maximal 200 Zeichen betragen.</span>
                <span v-else>Bitte geben Sie Ihre Strasse an.</span>
              </b-form-invalid-feedback>
            </div>
            <div class="form-group col-md-3">
              <label class="small">Hausnummer</label>
              <b-form-input
                  type="text"
                  class="form-control form-control-sm"
                  aria-describedby="housenumberInputField-live-feedback"
                  v-model.trim="$v.form.hausnummer.$model"
                  :state="validateState('hausnummer')">
              </b-form-input>
              <b-form-invalid-feedback id="housenumberInputField-live-feedback">
                <span v-if="!$v.form.hausnummer.maxLength">Ihre Hausnummer darf maximal 10 Zeichen betragen.</span>
              </b-form-invalid-feedback>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label class="small">Adresszusatz</label>
              <b-form-input
                  type="text"
                  class="form-control form-control-sm"
                  aria-describedby="zusatzInputField-live-feedback"
                  v-model.trim="$v.form.zusatz.$model"
                  :state="validateState('zusatz')">
              </b-form-input>
              <b-form-invalid-feedback id="zusatzInputField-live-feedback">
                <span v-if="!$v.form.zusatz.maxLength">Ihre Addresszusatz darf maximal 200 Zeichen betragen.</span>
              </b-form-invalid-feedback>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-3">
              <label class="small">PLZ</label>
              <b-form-input
                  type="text"
                  class="form-control form-control-sm"
                  aria-describedby="plzInputField-live-feedback"
                  v-model.trim="$v.form.plz.$model"
                  :state="validateState('plz')">
              </b-form-input>
              <b-form-invalid-feedback id="plzInputField-live-feedback">
                <span v-if="!$v.form.plz.maxLength">Ihre PLZ darf maximal 100 Zeichen betragen.</span>
                <span v-else>Bitte geben Sie Ihre PLZ an.</span>
              </b-form-invalid-feedback>
            </div>
            <div class="form-group col-md-9">
              <label class="small">Ort</label>
              <b-form-input
                  type="text"
                  class="form-control form-control-sm"
                  aria-describedby="cityInputField-live-feedback"
                  v-model.trim="$v.form.ort.$model"
                  :state="validateState('ort')">
              </b-form-input>
              <b-form-invalid-feedback id="cityInputField-live-feedback">
                <span v-if="!$v.form.ort.maxLength">Ihr Ort darf maximal 200 Zeichen betragen.</span>
                <span v-else>Bitte geben Sie Ihren Ort an.</span>
              </b-form-invalid-feedback>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label class="small">Land</label>

              <b-form-select
                  id="countryInputField"
                  size="sm"
                  class="form-control form-control-sm"
                  aria-describedby="countryInputField-live-feedback"
                  v-model.trim="$v.form.land.$model"
                  :options="countries">
                <template #first>
                  <b-form-select-option :value="null" disabled>-- Land --</b-form-select-option>
                </template>
              </b-form-select>
              <b-form-invalid-feedback id="countryInputField-live-feedback">
                <span>Bitte geben Sie Ihr Land an.</span>
              </b-form-invalid-feedback>
            </div>
          </div>  
          <div class="form-row mt-4" v-if="validationResults && validationResults.length > 0">
            <div class="text-left w-100">
              <b-alert show variant="danger" v-for="validationResult in validationResults" :key="validationResult">
                {{validationResult}}
              </b-alert>
            </div>
          </div>
        </b-form>
      </div>
    </div>
  </b-modal>

  <b-modal id="deleteAlternativeAddress"
           title="Abweichende Postanschrift löschen"
           header-bg-variant="dark"
           :hide-footer="isAddressDeleting">
    <template #modal-footer="{}">
      <b-button variant="secondary" @click="cancelDeleteAlternativeAddress" class="btn btn-default btn-form">Abbrechen</b-button>
      <b-button variant="primary" @click="deleteAddress" class="btn btn-default btn-form">OK</b-button>
    </template>
    <div class="text-center my-2" v-if="isAddressStoring">
      <b-spinner class="align-middle"></b-spinner>
      <strong class="ml-2">Die Adresse wird gelöscht...</strong>
    </div>
    <div v-else>
      <p class="my-4" v-if="hasAddressError">Beim Löschen ist ein Fehler aufgetreten.</p>
      <div class="my-4" v-else>
        <p>Möchten Sie Ihre abweichende Postanschrift wirklich löschen?</p>
      </div>
    </div>
  </b-modal>
  </div>

</template>

<script>
import {mapGetters} from "vuex";
import {validationMixin} from 'vuelidate'
import {maxLength,required} from "vuelidate/lib/validators";
import axios from "axios";

export default {
  name: "AddressModal",
  props: {
    addressData: {
      type: Object,
    }
  },
  data() {
    return {
      rawCountryData: [],
      countries: [],
      form: {
        strasse: '',
        hausnummer: '',
        zusatz: '',
        plz: '',
        ort: '',
        land: '',
        zweck: '',
      },
      validationResults: [],
    }
  },
  validations() {
    return {
      form: {
        strasse: {
          required,
          maxLength: maxLength(200),
        },
        hausnummer: {
          maxLength: maxLength(10),
        },
        zusatz: {
          maxLength: maxLength(200),
        },
        plz: {
          required,
          maxLength: maxLength(100),
        },
        ort: {
          required,
          maxLength: maxLength(200),
        },
        land: {
          required,
        }
      },
    }},
  created() {
    this.loadCountries();
  },
  computed: {
    ...mapGetters('user', ['userProfileData','status','isSuccess']),
    isAddressStoring() {
      return this.$store.getters["user/isWaiting"];
    },
    isAddressDeleting() {
      return this.$store.getters["user/isWaiting"];
    },
    hasAddressError() {
      var addressStatus = this.$store.getters["user/status"];
      return !(addressStatus === undefined || addressStatus === null || addressStatus === '' || addressStatus === "" )
    },
  },
  methods: {
    mapCountryKey( keySearch ) {
      for(let item in this.rawCountryData) {
        if ( this.rawCountryData[item].key === keySearch ) {
          return this.rawCountryData[item].value;
        }
      }
    },
    mapCountryValue( valueSearch ) {
      for(let item in this.rawCountryData) {
        if ( this.rawCountryData[item].value === valueSearch ) {
          return this.rawCountryData[item].key;
        }
      }
    },
    loadCountries() {
      return axios.get( this.$store.getters.getAllEnvis.API_LAENDER )
          .then(response => {
            this.rawCountryData = response.data;
            this.countries = [];
            // Map the raw data key:value (DE:Deutschland) to a format understood by the b-select component value:text (DE:Deutschland)
            for(let item in this.rawCountryData) {
              this.countries.push( { value: this.rawCountryData[item].key, text: this.rawCountryData[item].value } )
            }
          } )
          .catch(error => {
            console.log(error);
            return [];
          })
    },
    submitForm(event) {
      event.preventDefault();
      this.$v.$touch();
      if (this.$v.form.$anyError) {
        this.submitInProgress = false;
        return;
      }
      this.submitAddress();
    },
    submitAddress(){
      const payload = {
        strasse: this.form.strasse,
        hausnummer: this.form.hausnummer,
        zusatz: this.form.zusatz,
        plz: this.form.plz,
        ort: this.form.ort,
        land: this.form.land,
        zweck: this.addressData.zweck,
      }
      this.$store.dispatch('user/saveAdressData', payload);
    },
    deleteAddress(){
      const payload = {
        strasse: this.form.strasse,
        hausnummer: this.form.hausnummer,
        zusatz: this.form.zusatz,
        plz: this.form.plz,
        ort: this.form.ort,
        land: this.form.land,
        zweck: this.addressData.zweck,
      }
      this.$store.dispatch('user/deleteAddressData', payload);
    },
    validateState(fieldName) {
      const { $dirty, $error } = this.$v.form[fieldName];
      return $dirty ? !$error : null;
    },
    cancelChangeAddress() {
      this.$bvModal.hide( 'changeAddress' );
    },
    cancelDeleteAlternativeAddress() {
      this.$bvModal.hide( 'deleteAlternativeAddress' );
    }
  },
  mixins: [validationMixin],
  watch: {
    addressData: function() {
      if (this.addressData) {
        this.form.strasse = this.addressData.strasse;
        this.form.hausnummer = this.addressData.hausnummer;
        this.form.zusatz = this.addressData.zusatz;
        this.form.plz = this.addressData.plz;
        this.form.ort = this.addressData.ort;
        this.form.land = this.mapCountryValue( this.addressData.land );
      }
    },
    isSuccess: function() {
      if ( !this.isAddressStoring && this.isSuccess ) {
        this.$bvModal.hide( 'changeAddress' );
        this.$bvModal.hide( 'deleteAlternativeAddress' );
      }
    }
  }
}
</script>

<style scoped>

</style>