<template>
  <div>

    <AddressModal v-bind:addressData="addressData"></AddressModal>

    <section>
      <Hero>
        <div>
          <div class="container">
            <HeadText :titletext="'Mein Profil'" :subtext="''"></HeadText>
          </div>
          <div class="container bg-light pt-3 px-3" v-if="!hasToken()">
            <b-row>
              <b-col md="12" class="text-left">
                <p>
                  Sie sind nicht angemeldet. Bitte melden Sie sich <router-link to="/login">hier</router-link> an.
                </p>
              </b-col>
            </b-row>  
          </div>
          <div class="container bg-light pt-3 px-3" v-else>
            <b-row>
              <b-col md="6" class="text-left">
                <p>
                  Hier können Sie Ihre Anschrift ändern. Bitte denken Sie in dem Fall daran, ebenfalls Ihre zuständige
                  Treuhandgesellschaft über die Adressänderung zu informieren. Sobald Sie uns einen Verkaufsauftrag
                  erteilt haben, müssen die Auftragsdaten mit denen Ihres Treuhänders übereinstimmen, da sonst keine
                  Übertragung Ihrer Fondsanteile stattfinden kann. Bitte beachten Sie, dass unstimmige Informationen 
                  zu Verzögerungen im Auftragsablauf führen können.
                </p>
              </b-col>
              <b-col md="6" class="text-left">
                <FdbContact></FdbContact>
              </b-col>
            </b-row>
          </div>

          <div class="container pt-3 px-3">
            <b-row>
              <b-col class="pl-0 pr-0">
                <ul class="content-container">
                  <li class="container-item pb-3 px-sm-0 px-md-3" v-if="getProfile().typ === 'Person'">
                    <div class="bg-light p-2 userProfileBoxHead d-flex align-items-center">
                      <i class="mr-3 fas fa-user-circle" style="font-size: 2em;"></i>
                      Name
                    </div>
                    <div class="b-row userProfileBoxContent">
                      <div class="b-col">{{ getProfile().anrede }} {{ getProfile().titel }}</div>
                      <div class="b-col">{{ getProfile().vorname }} {{ getProfile().nachname }}</div>
                    </div>
                  </li>
                  <li class="container-item pb-3 px-sm-0 px-md-3" v-if="getProfile().typ === 'Firma'">
                    <div class="bg-light p-2 userProfileBoxHead d-flex align-items-center">
                      <i class="mr-3 fas fa-user-circle" style="font-size: 2em;"></i>
                      Firmenname
                    </div>
                    <div class="b-row userProfileBoxContent">
                      <div class="b-col">{{ getProfile().firmenname }}</div>
                    </div>
                  </li>
                  <li class="container-item pb-3 px-sm-0 px-md-3" v-if="getProfile().email">
                    <div class="p-2 userProfileBoxHead d-flex align-items-center">
                      <i class="mr-3 fas fa-at" style="font-size: 2em;"></i>
                      E-Mail
                    </div>
                    <div class="b-row userProfileBoxContent">
                      <div class="b-col">{{ getProfile().email.email }}</div>
                    </div>
                  </li>
                  <li class="container-item pb-3 px-sm-0 px-md-3" v-if="hasToken()">
                    <div class="p-2 userProfileBoxHead d-flex align-items-center">
                      <i class="mr-4 fas fa-map-marker-alt" style="font-size: 2em;"></i>
                      Anschrift
                    </div>
                    <div class="userProfileBoxContent">
                      <div v-if="getProfile().anschrift">
                        <p class="mb-1">{{ getProfile().anschrift.strasse }} {{ getProfile().anschrift.hausnummer }}</p>
                        <p class="mb-1">{{ getProfile().anschrift.zusatz }}</p>
                        <p>{{ getProfile().anschrift.plz }} {{ getProfile().anschrift.ort }}</p>
                        <p class="pt-2">{{ getProfile().anschrift.land }}</p>
                      </div>
                      <div v-else>
                        <p>Kein Eintrag vorhanden</p>
                      </div>
                    </div>
                    <div class="edit" v-if="hasToken()">
                      <a @click="changeAddress()" class="pt-2 pl-1 watchLink">
                        <i class="pointer fas fa-pen" style="font-size: 1em;"></i>
                      </a>
                    </div>
                  </li>
                  <li class="container-item pb-3 px-sm-0 px-md-3" v-if="hasToken()">
                    <div class="p-2 userProfileBoxHead d-flex align-items-center">
                      <i class="mr-4 fas fa-map-marker-alt" style="font-size: 2em;"></i>
                      Abweichende Postanschrift
                    </div>
                    <div class="userProfileBoxContent">
                      <div v-if="getProfile().alternativeanschrift">
                        <p class="mb-1">{{ getProfile().alternativeanschrift.strasse }} {{ getProfile().alternativeanschrift.hausnummer }}</p>
                        <p class="mb-1">{{ getProfile().alternativeanschrift.zusatz }}</p>
                        <p>{{ getProfile().alternativeanschrift.plz }} {{ getProfile().alternativeanschrift.ort }}</p>
                        <p class="pt-2">{{ getProfile().alternativeanschrift.land }}</p>
                      </div>
                      <div v-else>
                        <div v-if="!this.loadingSubsiteContent" v-html="this.hinweisAltPostanschrift"></div>
                      </div>
                      <div v-if="!this.loadingSubsiteContent">
                        <div v-html="this.hinweisErbOderInsolvenzfall"></div>
                      </div>
                    </div>
                    <div class="edit" v-if="hasToken()">
                      <a @click="changeAlternativeAddress()" class="pt-2 pl-1 watchLink">
                        <i class="pointer fas fa-pen" style="font-size: 1em;"></i>
                      </a>
                      <a v-if="showDeleteButton" @click="deleteAlternativeAddress" class="pt-2 pl-1 watchLink">
                        <i class="pointer fas fa-trash" style="font-size: 1em;"></i>
                      </a>
                    </div>
                  </li>
                  <li class="container-item pb-3 px-sm-0 px-md-3 mr-0" v-if="getProfile().telefon || getProfile().mobil">
                    <div class="p-2 userProfileBoxHead d-flex align-items-center">
                      <i class="mr-3 fas fa-phone" style="font-size: 2em;"></i>
                      Telefon
                    </div>
                    <div class="b-row userProfileBoxContent">
                      <div v-if="getProfile().telefon" class="b-col mb-2"><small>Festnetz</small><span>{{ formatCountryCode(getProfile().telefon.laenderkennung) }} {{ formatAreaCode(getProfile().telefon.vorwahl) }} {{ getProfile().telefon.rufnummer }}</span></div>
                      <div v-if="getProfile().mobil" class="b-col"><small>Mobil</small><span>{{ formatCountryCode(getProfile().mobil.laenderkennung) }} {{ formatAreaCode(getProfile().mobil.vorwahl) }} {{ getProfile().mobil.rufnummer }}</span></div>
                    </div>
                  </li>
                  <li class="container-item pb-3 px-sm-0 px-md-3" v-if="getProfile().fax">
                    <div class="p-2 userProfileBoxHead d-flex align-items-center">
                      <i class="mr-3 fas fa-fax" style="font-size: 2em;"></i>
                      Fax
                    </div>
                    <div class="b-row userProfileBoxContent">
                      <div class="b-col">{{ formatCountryCode(getProfile().fax.laenderkennung) }} {{ formatAreaCode(getProfile().fax.vorwahl) }} {{ getProfile().fax.rufnummer }}</div>
                    </div>
                  </li>
                </ul>
              </b-col>
            </b-row>
          </div>
        </div>
      </Hero>
    </section>  
  </div>
</template>

<script>
import Hero from "@/components/HeroPicture";
import HeadText from "@/components/HeadText";
import FdbContact from "@/components/FdbContact";
import AddressModal from "@/components/user/AddressModal";
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "UserProfile",
  components: {Hero, HeadText, FdbContact, AddressModal},
  data() {
    return {
      addressData: {},
      subsiteContent: [],
      loadingSubsiteContent: false,
      hinweisAltPostanschrift: '',
      hinweisErbOderInsolvenzfall: '',
    }
  },
  computed: {
    ...mapGetters('user', ['userProfileData','status']),
    showDeleteButton() {
      if ( !this.getProfile() || !this.getProfile().alternativeanschrift ) {
        return false;
      } else if ((this.getProfile().alternativeanschrift.strasse === "") &&
          (this.getProfile().alternativeanschrift.hausnummer === "") &&
          (this.getProfile().alternativeanschrift.zusatz === "") &&
          (this.getProfile().alternativeanschrift.plz === "") &&
          (this.getProfile().alternativeanschrift.land === "")) {
        return false;
      } else {
        return true;
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.loadProfile();
    })
  },
  mounted() {
    this.loadSubsiteContent();
  },
  metaInfo() {
    return {
      title: this.getClientConfig('name') + ' | ' + this.getClientConfig('title'),
      meta: [
        {
          name: 'description',
          content: 'Der Marktplatz für geschlossene Fonds. Wir bieten freien und institutionellen Vertriebsgesellschaften das führende Service- und Plattformangebot für die Auswahl, den Vertrieb, die Abwicklung und die Bestandsverwaltung geschlossener Fonds in Deutschland und Österreich.',
        },
      ],
    }
  },
  methods: {
    getClientConfig(path) {
      return this.$store.getters['clientConfig/config'](path)
    },
    loadProfile() {
      this.$store.dispatch('user/loadUserProfile')
    },
    getProfile() {
      return this.$store.getters["user/userProfileData"];
    },
    loadSubsiteContent() {
      let token = this.$store.getters['auth/getToken']
      if(token === '' || token === undefined){
        throw new Error('Missing Token!')
      }
      this.loadingSubsiteContent = true;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        crossdomain: true,
      };
      const uri = this.$store.getters.getAllEnvis.API_SUBSITE_CONTENT + '/protected?contentTypes=32230,32240';
      axios.get(uri, config)
          .then(response => {
            this.subsiteContent = response.data.content;
            this.loadingSubsiteContent = false;
            this.hinweisAltPostanschrift = this.getHtmlContent( 32230 );
            this.hinweisErbOderInsolvenzfall = this.getHtmlContent( 32240 );
          } )
          .catch(error => {
            console.log( error );
            this.loadingSubsiteContent = true;
          })  
    },
    getHtmlContent( contentType ) {
      for ( let item in this.subsiteContent ) {
        var contentItem = this.subsiteContent[item];
        if ( contentType === contentItem.contentType.id ) {
          return contentItem.htmlcontent;
        }
      }
      return "No content for ID " + contentType;
    },
    hasToken() {
      return this.$store.getters["auth/hasToken"];
    },
    formatCountryCode( n ) {
      return n ? n.replace("00", "+") : '';
    },
    formatAreaCode( n ) {
      return n ? n.replace(/^0+/, '') : '';
    },
    changeAddress () {
      this.addressData = this.$store.getters["user/userProfileData"].anschrift
      this.$bvModal.show( 'changeAddress' );
    },
    changeAlternativeAddress () {
      this.addressData = this.$store.getters["user/userProfileData"].alternativeanschrift
      this.$bvModal.show( 'changeAddress' );
    },
    deleteAlternativeAddress() {
      this.addressData = this.$store.getters["user/userProfileData"].alternativeanschrift
      this.$bvModal.show( 'deleteAlternativeAddress' );
    }
  },
}
</script>

<style lang="scss">

</style>